<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>价值包参数配置</a-breadcrumb-item>
        <a-breadcrumb-item>类型管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="false"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="action" slot-scope="text, record">
            <a-space>
              <a @click="modifyValue(record)">修改</a>
            </a-space>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal
      title="修改价值包"
      :visible="showModify"
      ok-text="确定"
      cancel-text="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form :form="createForm" class="advanced-search-form">
        <a-row>
          <a-col :span="20">
            <a-form-item label="价值包名称" class="form-item">
              <a-input
                v-decorator="[
                  'modelName',
                  {
                    rules: [
                      { required: true, message: '请填写价值包' },
                      { validator: modelNames, message: '不可为空' },
                      { validator: modelNameMax, message: '不可多于50字' }
                    ]
                  }
                ]"
                placeholder="请输入价值包名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="20">
            <a-form-item label="允许运营中心设定" class="form-item">
              <a-radio-group
                v-decorator="[
                  'modelStatus',
                  {
                    rules: [{ required: true, message: '请选择时间' }]
                  }
                ]"
              >
                <a-radio :value="0">允许</a-radio>
                <a-radio :value="1">不允许</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { tsFormat } from "@/components/DateUtils";
import { fetchPolicy, modifyPolicy } from "@/services/ValueBag";

const tblColumns = [
  {
    title: "价值包名称",
    dataIndex: "policyTypeName",
    width: "34%"
  },
  {
    title: "加价/计提标准设定权限",
    dataIndex: "allowSets",
    width: "45%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "19%"
  }
];
export default {
  data() {
    return {
      fields: [
        {
          label: "价值包名称",
          key: "name",
          component: "j-input"
        },
        {
          label: "运营中心设定加价/计提标准",
          key: "allowSet",
          component: "j-radio-group",
          options: [
            {
              label: "允许",
              value: "0"
            },
            {
              label: "不允许",
              value: "1"
            }
          ]
        }
      ],
      tsFormat,
      form: {},
      createForm: this.$form.createForm(this, { name: "createForm" }),
      tblData: [],
      tblColumns,
      showModify: false,
      modelName: "",
      modelStatus: "",
      modelId: "",
      loading: false
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    modelNameMax(rule, value) {
      if (value.length <= 50) {
        return true;
      } else {
        return false;
      }
    },
    // 获取列表信息
    fetchList() {
      fetchPolicy(this.form.name || "", this.form.allowSet || "").then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.tblData = resp.data.data;
          this.tblData = this.tblData.filter(item => {
            return item.policyTypeId !== "SalesAdded";
          });
          this.tblData.forEach(item => {
            if (!item.allowSet) {
              item.allowSets = "允许";
            } else {
              item.allowSets = "不允许";
            }
          });
        }
      });
    },
    modelNames(rule, value) {
      if (value.trim()) {
        return true;
      } else {
        return false;
      }
    },
    // 修改弹框赋值
    modifyValue(record) {
      this.showModify = true;
      this.$nextTick(() => {
        this.createForm.setFieldsValue({ modelName: record.policyTypeName });
        this.createForm.setFieldsValue({ modelStatus: record.allowSet });
      });
      this.modelId = record.policyTypeId;
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.fetchList();
    },
    // 弹框确认
    handleOk() {
      this.createForm.validateFields(err => {
        if (!err) {
          const params = {
            allowSet: this.createForm.getFieldsValue().modelStatus,
            policyTypeId: this.modelId,
            policyTypeName: this.createForm.getFieldsValue().modelName
          };
          modifyPolicy(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("修改成功");
              this.showModify = false;
              this.fetchList();
            }
          });
        }
      });
    },
    // 弹框取消
    handleCancel() {
      this.showModify = false;
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
  margin-right: 40px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.search__btn button {
  margin-right: 8px;
}
/deep/ .common-search_form-item {
  width: 33%;
  min-width: 400px;
}
/deep/ .common-search_form-item .ant-form-item-label {
  width: 200px;
}
</style>
