var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v("价值包参数配置")]),_c('a-breadcrumb-item',[_vm._v("类型管理")])],1)],1),_c('div',{staticClass:"content-container"},[_c('common-search',{attrs:{"fields":_vm.fields},on:{"search":_vm.onSearch}}),_c('div',{staticClass:"main-content"},[_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"pagination":false,"loading":_vm.loading,"row-key":function (record, index) {
            return index;
          }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a-space',[_c('a',{on:{"click":function($event){return _vm.modifyValue(record)}}},[_vm._v("修改")])])]}}])})],1)],1),_c('a-modal',{attrs:{"title":"修改价值包","visible":_vm.showModify,"ok-text":"确定","cancel-text":"取消"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.createForm}},[_c('a-row',[_c('a-col',{attrs:{"span":20}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"价值包名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'modelName',
                {
                  rules: [
                    { required: true, message: '请填写价值包' },
                    { validator: _vm.modelNames, message: '不可为空' },
                    { validator: _vm.modelNameMax, message: '不可多于50字' }
                  ]
                }
              ]),expression:"[\n                'modelName',\n                {\n                  rules: [\n                    { required: true, message: '请填写价值包' },\n                    { validator: modelNames, message: '不可为空' },\n                    { validator: modelNameMax, message: '不可多于50字' }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请输入价值包名称","allow-clear":""}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":20}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"允许运营中心设定"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'modelStatus',
                {
                  rules: [{ required: true, message: '请选择时间' }]
                }
              ]),expression:"[\n                'modelStatus',\n                {\n                  rules: [{ required: true, message: '请选择时间' }]\n                }\n              ]"}]},[_c('a-radio',{attrs:{"value":0}},[_vm._v("允许")]),_c('a-radio',{attrs:{"value":1}},[_vm._v("不允许")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }